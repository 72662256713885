import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/seo'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import * as styles from '../styles/privacy.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Privacy Policy" }) {
        edges {
          node {
            content
          }
        }
      }
    }
  }
`

const PrivacyPolicy = ({ data }) => {
  const content = data.wordPress.pages.edges[0].node.content
  return (
    <>
      <SEO
        description='Privacy Policy for the Deimos Website'
        title='Privacy Policy' />
      <div className={styles.privacyContainer}>
        <div className={styles.flexContainerInner}>
          {parse(content)}
        </div>
      </div>
    </>
  )
}

PrivacyPolicy.propTypes = {
  data: PropTypes.object
}

export default PrivacyPolicy
